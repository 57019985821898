$colSpacing: 16;

.grid {
    margin-top: 3.75rem;
    @media (min-width: $desktop) {
        display: flex;
        flex-wrap: wrap;
    }
}

.col {
    margin: $(colSpacing)px;
    text-decoration: none;

    @media (min-width: $desktop) {
        flex: 1 0 calc(33% - $(colSpacing)px * 2);
        maxWidth: calc(33% - $(colSpacing)px * 2);
    }
    @media (min-width: $desktopLarge) {
        flex: 1 0 calc(25% - $(colSpacing)px * 2);
        maxWidth: calc(25% - $(colSpacing)px * 2);
    }
}

.extendTitle {
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--blue)
}
