$spaceGap: 15;

.container {
    border: 0;
    border-radius: 50%;
    background-color: var(--blue);
    padding: 14px 28px;
    font-size: 16px;
    color: var(--white);
    display: block;
    transform: rotate(-10deg);
    position: relative;
    text-decoration: none;
    margin: 0 1rem 2rem 0;
    box-sizing: border-box;
    width: auto;
    overflow: visible;
    line-height: 1;
    white-space: normal;
    -webkit-appearance: none;

    &.container:before {
        content: " ";
        border-radius: 50%;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateY($(spaceGap)%);
        border: 2px solid var(--white);
        font-size: 0;
        transition: transform $transition;
    }

    &.container:after {
        background-color: var(--blue);
        content: " ";
        border-radius: 50%;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 2px solid var(--white);
        font-size: 0;
        transition: transform $transition;
    }

    &.container:hover {
        &.container:after {
            transform: translateY($(spaceGap)%);
        }

        &.container * {
            transform: translateY(50%);
        }
    }
}

.innerWrapper {
    position: relative;
    z-index: 2;
    transition: transform $transition;
    line-height: auto;
    display: block;
}
