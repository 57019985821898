
.container {
    color: var(--white);

    font-size: 2rem;
    text-shadow: 4px 4px var(--black);

    font-weight: 500;
    letter-spacing: 6.18px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    line-height: auto;
    text-align: center;

    &.container[responsive] {
        @media (min-width: $tablet) {
            font-size: 5.5rem;
            text-shadow: 6px 6px var(--black);
        }

        @media (min-width: $tabletLarge) and (min-height: 800px) {
            font-size: 8.25rem;
            text-shadow: 8px 8px var(--black);
        }
    }

}
