.extendSection {
    background-color: var(--blue) !important;
    color: var(--white);

    & .extendSectionInner {
        max-width: 54rem;

        @media (min-width: $desktopFullHD) : {
            max-width: 71rem;
        }
    }
}

.extendTitle {
    color: var(--white);

    font-size: 2.5rem;
    margin-bottom: 2rem;

    letter-spacing: 6.18px;
    text-align: left;
    position: relative;
    z-index: 2;

    @media (min-width: $tablet) {
        font-size: 8.5rem;
        margin-bottom: 0;
    }

    @media (min-width: $tabletLarge) {
        font-size: 8.5rem;
        margin-bottom: 0;
    }
}

.extendParagraph {
    padding-bottom: 3rem;
    font-size: 1.25rem;
    line-height: 1.65rem;

    @media (min-width: $tabletLarge) {
        padding-right: 10rem;
        font-size: 1.25rem;
        line-height: 1.65rem;
    }
}

.inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $tabletLarge) {
        /* This is a fix for a weird behaviour where .inner and .left would get combined under one selector, but this media queries would be before them */
        &.inner {
            flex-direction: row;
            margin-top: -5.5rem;
        }
    }
}

.left {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    order: 2;
    padding-top: 1.5rem;

    @media (min-width: $tabletLarge) {
        padding-top: 5.5rem;
        order: 1;
        max-width: 30rem;
    }

    @media (min-width: $desktopFullHD) {
        max-width: 47rem;
    }
}

.right {
    order: 1;
    @media (min-width: $tabletLarge) {
        order: 2;
    }
}

.unicorn {
    height: auto;
    max-width: 100%;
}
