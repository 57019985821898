
.extendTitle {
    text-shadow: 2px 2px var(--blue);
    color: var(--white);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
}

$colSpacing: 32;

.grid {
    margin-top: 3.75rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

}

.col {
    margin: $(colSpacing)px;
    text-decoration: none;

    @media (min-width: $desktop) {
        flex: 0 0 calc(16.666% - $(colSpacing)px * 2);
    }
}
