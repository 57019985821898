/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/1e0eb4a7325b5d14-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/136ae42764b7e0db-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/b17f4305e2f0c22a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/a7f64a09f7737545-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/_next/static/media/98bcafdc59ee6d65-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/5a7dbca54c2fc4dd-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/d4e99c199dab3055-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/9ae1ac821a4debaf-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/ec1b1cbc512bc43e-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/463cef7d4672ba68-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/b6f2eee8808a2bb4-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/bc2003170c651d45-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d43ef4503e5571d0-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/3281a323710833ec-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/684e5662d94c69e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7524e759b2d52ce2-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/83feb047e42e457a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/40bfb1458eaec497-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8ea51a27e153ec43-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_ee187e';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/035951aefad7b653-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_ee187e';src: local("Arial");ascent-override: 75.41%;descent-override: 20.23%;line-gap-override: 0.00%;size-adjust: 135.93%
}.__className_ee187e {font-family: '__IBM_Plex_Mono_ee187e', '__IBM_Plex_Mono_Fallback_ee187e';font-style: normal
}

.Footer_link__o5qtX {
    padding: 16px;

    display: flex;
    align-items: center;
    text-decoration: none;
}

.Footer_box__arvfE {
    background-color: var(--blue);
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--white);
    margin-right: 2.5rem;
}

.Footer_extendSectionInner__DQZmM {
    max-width: 70rem;
    color: var(--white);

    display: grid;
    grid-template-columns: auto;

    @media (min-width: 425px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
}


.Section_wrapper__t3K4k {
    padding: 3rem 1rem;

    @media (min-width: 768px) {
        &.Section_wrapper__t3K4k {
            padding: 7.5rem 2rem;
        }
    }

    width: 100%
}

.Section_inner__TMTOK {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
}

.Blogs_extendSection__ZU_5B {
    background-color: var(--white);
    color: var(--black);
    height: 100%;
}

.Blogs_extendTitle__PKHw6 {
    color: var(--blue);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--black);
}

.Blogs_grid__mwtHR {
    margin-top: 3.75rem;
    max-width: 58rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1025px) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: 1921px) {
        max-width: 73rem;
    }
}

.Blogs_col__a3GMe {
    display: flex;
    margin: 16px;
    text-decoration: none;

    @media (min-width: 1025px) {
        flex: 0 0 calc(50% - calc(16px * 2));
    }
}

.Blogs_readMoreWrapper__6efTu {
    margin-top: 3.25rem;
    text-align: center;
}

.Blogs_extendCardBody__AT_UR {
    padding: 0;
    min-height: unset;
}

.Blogs_extendCardWrapper__0KKBb {
    height: 100%;
    flex-grow: 1;
}


.LargeTitle_container__K_e8h {
    color: var(--white);

    font-size: 2rem;
    text-shadow: 4px 4px var(--black);

    font-weight: 500;
    letter-spacing: 6.18px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    line-height: auto;
    text-align: center;

    &.LargeTitle_container__K_e8h[responsive] {
        @media (min-width: 768px) {
            font-size: 5.5rem;
            text-shadow: 6px 6px var(--black);
        }

        @media (min-width: 1024px) and (min-height: 800px) {
            font-size: 8.25rem;
            text-shadow: 8px 8px var(--black);
        }
    }

}


.Card_wrapper__y80Ds {
    border: 2px solid var(--black);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;

    &.Card_wrapper__y80Ds:hover {
        &.Card_wrapper__y80Ds [role="link"] {
            opacity: 0.3;
        }
    }
}

.Card_body__7iHDq {
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Card_footer__mYT13 {
    border-top: 2px solid var(--black);
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 1.25rem;
    font-weight: 100;
}

.Card_button__j2aW2 {
    display: flex;
    justify-content: center;
    background-color: var(--cyan);
    width: 3.125rem;
    align-items: center;
    padding: 0.625rem;
    text-align: center;
    height: 100%;
    border-left: 2px solid var(--black);
    text-decoration: none;
    transition: 0.3s opacity ease;
}

.Header_header__9PD52 {
    background-color: var(--blue);
    height: 100%;
    padding-bottom: 3rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: 1024px) {
        min-height: 100svh;
        justify-content: space-between;
        padding-bottom: 6rem;
    }
}

.Header_topWrapper__CIoSK {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 1.5rem;
    width: 100%;
    @media (min-width: 1024px) {
        padding: 3.75rem 5rem 2.5rem 3.75rem;
    }
}

.Header_joinUs__XcqTv {
    border: none;
    text-transform: uppercase;
    background-color: var(--white);
    color: var(--blue);
    padding: 8px 12px;
    display: block;
    text-decoration: none;
}

.Header_titleWrapper__5bidl {
    text-align: center;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.Header_contentWrapper__xPnOy {
    width: 100%;
    max-width: 52rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3.75rem;
    margin-left: auto;
    margin-right: auto;
}

.Header_contentTextWrapper__gadj7 {
    padding: 0 1.5rem;

    @media (min-width: 1024px) {
        padding: 0 6rem;
    }
}

.Header_readMore__Vvjb9 {
    margin-top: 2rem;

    margin-left: auto;
    margin-right: auto;
    color: var(--white);
    display: block;
    border: none;
    text-decoration: none;

    @media (min-width: 1024px) {
        margin-right: unset;
        margin-left: auto;
        margin-bottom: 2rem;
    }
}

.Header_extendTitle__6f7fe {
    padding-left: 10rem;
    padding-right: 10rem;
}

.Header_extendParagraph__vsiOX {
    @media (max-height: 880px) : {
        font-size: 1rem;
        line-height: 1.55rem;
    }
}

.OvalButton_container__SsSWx {
    border: 0;
    border-radius: 50%;
    background-color: var(--blue);
    padding: 14px 28px;
    font-size: 16px;
    color: var(--white);
    display: block;
    transform: rotate(-10deg);
    position: relative;
    text-decoration: none;
    margin: 0 1rem 2rem 0;
    box-sizing: border-box;
    width: auto;
    overflow: visible;
    line-height: 1;
    white-space: normal;
    -webkit-appearance: none;

    &.OvalButton_container__SsSWx:before {
        content: " ";
        border-radius: 50%;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateY(15%);
        border: 2px solid var(--white);
        font-size: 0;
        transition: transform cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s;
    }

    &.OvalButton_container__SsSWx:after {
        background-color: var(--blue);
        content: " ";
        border-radius: 50%;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 2px solid var(--white);
        font-size: 0;
        transition: transform cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s;
    }

    &.OvalButton_container__SsSWx:hover {
        &.OvalButton_container__SsSWx:after {
            transform: translateY(15%);
        }

        &.OvalButton_container__SsSWx * {
            transform: translateY(50%);
        }
    }
}

.OvalButton_innerWrapper___uWp4 {
    position: relative;
    z-index: 2;
    transition: transform cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s;
    line-height: auto;
    display: block;
}

@keyframes Logo_rotation__D07rB {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.Logo_logo__iU_Ec{
    animation: 5s Logo_rotation__D07rB 1 linear;
    animation-iteration-count: infinite;
}
.Projects_extendSection__amt9L {
    background-color: var(--blue);
    color: var(--white);
    max-width: 88rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.Projects_extendTitle___EQja {
    color: var(--white);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--black);
}

.Projects_grid__7ywfz {
    margin-top: 3.75rem;
    @media (min-width: 1025px) {
        display: flex;
        flex-wrap: wrap;
    }

    /* maxWidth: 53rem; */
    margin-left: auto;
    margin-right: auto;
}

.Projects_readMoreWrapper__ebAZe {
    margin-top: 3.25rem;
    text-align: center;
}

.ProjectCard_col__VGHj5 {
    margin: 16px;
    display: block;
    text-decoration: none;

    @media (min-width: 1025px) {
        flex: 1 0 calc(50% - 16px * 2);

        &.ProjectCard_col__VGHj5:first-child {
            flex: 1 1 100%;
        }
    }
}

.JoinUs_extendSection__sD3Ha {
    background-color: var(--blue) !important;
    color: var(--white);

    & .JoinUs_extendSectionInner__9qy4J {
        max-width: 54rem;

        @media (min-width: 1921px) : {
            max-width: 71rem;
        }
    }
}

.JoinUs_extendTitle__uqx4c {
    color: var(--white);

    font-size: 2.5rem;
    margin-bottom: 2rem;

    letter-spacing: 6.18px;
    text-align: left;
    position: relative;
    z-index: 2;

    @media (min-width: 768px) {
        font-size: 8.5rem;
        margin-bottom: 0;
    }

    @media (min-width: 1024px) {
        font-size: 8.5rem;
        margin-bottom: 0;
    }
}

.JoinUs_extendParagraph__vj_D0 {
    padding-bottom: 3rem;
    font-size: 1.25rem;
    line-height: 1.65rem;

    @media (min-width: 1024px) {
        padding-right: 10rem;
        font-size: 1.25rem;
        line-height: 1.65rem;
    }
}

.JoinUs_inner__8VL8K {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: 1024px) {
        /* This is a fix for a weird behaviour where .inner and .left would get combined under one selector, but this media queries would be before them */
        &.JoinUs_inner__8VL8K {
            flex-direction: row;
            margin-top: -5.5rem;
        }
    }
}

.JoinUs_left__mGJLs {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    order: 2;
    padding-top: 1.5rem;

    @media (min-width: 1024px) {
        padding-top: 5.5rem;
        order: 1;
        max-width: 30rem;
    }

    @media (min-width: 1921px) {
        max-width: 47rem;
    }
}

.JoinUs_right__9R3hb {
    order: 1;
    @media (min-width: 1024px) {
        order: 2;
    }
}

.JoinUs_unicorn__ztt0z {
    height: auto;
    max-width: 100%;
}

.OpenSource_extendSection__i876c {
    background-color: var(--blue);
    color: var(--white);
}

.OpenSource_extendTitle__1S3rZ {
    color: var(--white);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--black);
}

.OpenSource_sectionWrapper__zOOKu {
    margin-top: 3.5rem;
    display: flex;
    flex-wrap: wrap;


    & .OpenSource_buttonWrapper__b2_cQ {
        margin-right: 5rem;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        min-width: 12rem;

        order: 2;
        margin-top: 2rem;
        @media (min-width: 768px) {
            margin-top: 0;
            order: 1;
        }
    }
}

.OpenSource_extendParagraph__d3Nuy {
    max-width: 34rem;
    width: 100%;
    font-weight: 100;
    letter-spacing: 0.91px;

    order: 1;
    @media (min-width: 768px) {
        order: 2;
    }
}

.OpenSource_extendSectionInner__Tou4O {
    max-width: 58rem;

    padding-left: 1rem;
    padding-right: 1rem;
}



.Paragraph_container__AqQcG {
    color: var(--white);
    font-size: 1rem;
    line-height: 1.65rem;
    word-break: break-word;

    @media (min-width: 1024px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    letter-spacing: 1.09px;
    font-weight: 300;
    margin: 0;
}

.SimpleLink_link__HSIIl {
    --SimpleLink-opacity: 1;

    color: var(--white);
    transition: 0.3s opacity ease;
    opacity: var(--SimpleLink-opacity);

    &.SimpleLink_link__HSIIl:active {
        color: var(--white);
    }

    &.SimpleLink_link__HSIIl:hover {
        color: var(--white);
        text-decoration: none;
    }
}

.StackedButton_container__Zn4Bg {
    border: 0;
    cursor: pointer;
    color: var(--baseColor, var(--blue));
    padding: 20px 32px;
    font-size: 16px;
    display: inline-block;
    position: relative;
    text-decoration: none;
    margin: 0 1rem 2rem 0;
    box-sizing: border-box;
    width: auto;
    overflow: visible;
    line-height: 1;
    white-space: normal;
    -webkit-appearance: none;
    font-weight: 500;
    letter-spacing: 0.91px;

    &.StackedButton_container__Zn4Bg:before {
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translate(8px, 8px);
        border: 2px solid var(--baseColor, var(--white));
        font-size: 0;
        transition: transform cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s;
    }


    &.StackedButton_container__Zn4Bg:after {
        background: var(--white);
        content: ' ';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 2px solid var(--baseColor, var(--white));
        font-size: 0;
        transition: transform cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s;
    }

    &.StackedButton_container__Zn4Bg:hover {
        &.StackedButton_container__Zn4Bg:after {
            transform: translate(8px, 8px);
        }

        &.StackedButton_container__Zn4Bg > * {
            transform: translate(8px, 50%);
        }
    }
}

.StackedButton_innerWrapper__yJY61 {
    position: relative;
    z-index: 2;
    transition: transform cubic-bezier(0.4, 0.0, 0.2, 1) 0.15s;
    line-height: auto;
    display: block;
}

.Layout_wrapper__u5FAJ {
    background-color: var(--white);
    color: var(--black);
    border: 2px solid var(--blue);
    padding-top: 7.5rem 2rem;
    max-width: 100%;
    position: relative;
    overflow: visible;
    height: 100%;
    width: 100%;
}

.Layout_wrapperBg__iUXGR {
    background-color: var(--blue);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 1;
}

.Layout_content__veLgD {
    background-color: var(--white);
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Layout_header__eeyYq {
    border-bottom: 2px solid var(--blue);
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.Layout_headerLeft__hQg22 {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    letter-spacing: 0.3px;
    font-weight: 500;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.Layout_headerRight__Yod6q {
    border-left: 2px solid var(--blue);
    display: flex;
    align-items: center;
    justify-items: center;
    width: 4.5rem;
    height: 100%;

    &.Layout_headerRight__Yod6q > * {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.Layout_body__C4X9f {
    background-color: var(--white);
    height: 100%;
}

.TeamCard_picture__cU2C3{
    display: flex;
    justify-items: center;
    align-items: center;
    margin: 3rem auto;
    background-color: var(--black);
    border-radius: 50%;
    height: 15rem;
    width: 15rem;
    flex-shrink: 0;
    overflow: hidden;
}

.TeamCard_pictureCircle__CCQ77{
    margin: auto;
    position: relative;
    border-radius: 50%;
}

.TeamCard_bodyRow__Mh6Gu{
    border-top: 2px solid var(--blue);
    display: flex;
    width: 100%;
    padding: 0.5rem 1.25rem;
    align-items: center;
    min-height: 3.125rem;
    color: var(--black);
}

.TeamCard_bodyMottoRow__WzzSZ{
    padding: 0 0 0 1.25rem;
    display: flex;
    justify-content: space-between;
}

.TeamCard_backBody__A5cig{
    background-color: var(--blue);
    height: 100%;
    width: 100%;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.625rem 0.625rem 0;
}

.TeamCard_paragraphExtend__Co6I_ {
    font-size: 1rem;
    letter-spacing: 0.73px;
    line-height: 1.5rem;
    padding: 1.5rem 2rem;

    @media (min-width: 1024px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

.TeamCard_video__EIdiY {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 50%;
}

.FlipButton_flipButton__ENTiU {
    background-color: var(--cyan);
    width: 4.5rem;
    height: 100%;
    padding: 0.625rem;;
    transition: 0.3s opacity ease;
    position: relative;
    z-index: 2;

    &.FlipButton_flipButton__ENTiU:hover {
        opacity: 0.3;
    }

    &.FlipButton_back__3Vwjk:hover {
        opacity: 0.7;
    }

}

.TeamFlippableCard_wrapper__7zeon {
    position: relative;
    display: flex;
    flex-direction: column;
    transform-style: preserve-3d;
    transition: transform cubic-bezier(0.5,0.25,0,1) 0.6s;
    transform: rotateY(0deg);

    &.TeamFlippableCard_wrapper__7zeon[data-flipped] {
        transform: rotateY(180deg);
    }
}

.TeamFlippableCard_frontWrapper__7AV0g {
    position: relative;
    flex: 1;
    z-index: 2;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transition: transform cubic-bezier(0.5,0.25,0,1) 0.6s;
}

.TeamFlippableCard_backWrapper__E7pgv {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    flex-grow: 1;
    flex-shrink: 0;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: rotateY(180deg);
}

.TeamFlippableCard_backBody__JXS9U {
    display: flex;
}

html {
  font-family: 'IBMPlexMono', monospace;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  overflow-x: hidden;
}
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #000000;
  color: #ffffff;
}

button {
  font-family: 'IBMPlexMono', monospace;
}

* {
  outline: none;
}

input,
label,
select,
button,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;

  /* Browsers have different default form fonts */
  font-size: 13px;
  font-family: 'IBMPlexMono', monospace;
}

/* Remove the stupid outer glow in Webkit */
input:focus {
  outline: 0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type='reset'],
input[type='button'],
input[type='submit'],
input[type='checkbox'],
input[type='radio'],
select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Text Inputs
-----------------------------------------------*/

input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='range'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'] {
}

/* Button Controls
-----------------------------------------------*/

input[type='checkbox'],
input[type='radio'] {
  width: 13px;
  height: 13px;
}

/* File Uploads
-----------------------------------------------*/

input[type='file'] {
}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration {
  display: none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type='reset'],
input[type='button'],
input[type='submit'] {
  /* Fix IE7 display bug */
  overflow: visible;
  width: auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button {
  padding: 0;
  border: 0;
  background: none;
}

/* Textarea
-----------------------------------------------*/

textarea {
  /* Move the label to the top */
  vertical-align: top;

  /* Turn off scroll bars in IE unless needed */
  overflow: auto;
}

/* Selects
-----------------------------------------------*/

select[multiple] {
  /* Move the label to the top */
  vertical-align: top;
}

html {
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

figure {
  margin: 0;
}

* {
  box-sizing: border-box;
}

:root {
    --black: black;
    --white: white;
    --cyan: #00ffff;
    --fuchsia: #ff00ff;
    --blue: #0000ff;
}


.Team_grid__9czyM {
    margin-top: 3.75rem;
    @media (min-width: 1025px) {
        display: flex;
        flex-wrap: wrap;
    }
}

.Team_col__Jk81o {
    margin: 16px;
    text-decoration: none;

    @media (min-width: 1025px) {
        flex: 1 0 calc(33% - 16px * 2);
        maxWidth: calc(33% - 16px * 2);
    }
    @media (min-width: 1440px) {
        flex: 1 0 calc(25% - 16px * 2);
        maxWidth: calc(25% - 16px * 2);
    }
}

.Team_extendTitle__c52kq {
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--blue)
}


.Technologies_extendTitle___UBI8 {
    text-shadow: 2px 2px var(--blue);
    color: var(--white);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
}

.Technologies_grid__EZB5F {
    margin-top: 3.75rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

}

.Technologies_col__MdXKG {
    margin: 32px;
    text-decoration: none;

    @media (min-width: 1025px) {
        flex: 0 0 calc(16.666% - 32px * 2);
    }
}

