.extendSection {
    background-color: var(--blue);
    color: var(--white);
}

.extendTitle {
    color: var(--white);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--black);
}

.sectionWrapper {
    margin-top: 3.5rem;
    display: flex;
    flex-wrap: wrap;


    & .buttonWrapper {
        margin-right: 5rem;
        display: inline-flex;
        text-align: center;
        justify-content: center;
        min-width: 12rem;

        order: 2;
        margin-top: 2rem;
        @media (min-width: $tablet) {
            margin-top: 0;
            order: 1;
        }
    }
}

.extendParagraph {
    max-width: 34rem;
    width: 100%;
    font-weight: 100;
    letter-spacing: 0.91px;

    order: 1;
    @media (min-width: $tablet) {
        order: 2;
    }
}

.extendSectionInner {
    max-width: 58rem;

    padding-left: 1rem;
    padding-right: 1rem;
}

