
.wrapper {
    padding: 3rem 1rem;

    @media (min-width: $tablet) {
        &.wrapper {
            padding: 7.5rem 2rem;
        }
    }

    width: 100%
}

.inner {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
}
