$colSpacing: 16;

.col {
    margin: $(colSpacing)px;
    display: block;
    text-decoration: none;

    @media (min-width: $desktop) {
        flex: 1 0 calc(50% - $(colSpacing)px * 2);

        &.col:first-child {
            flex: 1 1 100%;
        }
    }
}
