.header {
    background-color: var(--blue);
    height: 100%;
    padding-bottom: 3rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: $tabletLarge) {
        min-height: 100svh;
        justify-content: space-between;
        padding-bottom: 6rem;
    }
}

.topWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 1.5rem;
    width: 100%;
    @media (min-width: $tabletLarge) {
        padding: 3.75rem 5rem 2.5rem 3.75rem;
    }
}

.joinUs {
    border: none;
    text-transform: uppercase;
    background-color: var(--white);
    color: var(--blue);
    padding: 8px 12px;
    display: block;
    text-decoration: none;
}

.titleWrapper {
    text-align: center;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.contentWrapper {
    width: 100%;
    max-width: 52rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3.75rem;
    margin-left: auto;
    margin-right: auto;
}

.contentTextWrapper {
    padding: 0 1.5rem;

    @media (min-width: $tabletLarge) {
        padding: 0 6rem;
    }
}

.readMore {
    margin-top: 2rem;

    margin-left: auto;
    margin-right: auto;
    color: var(--white);
    display: block;
    border: none;
    text-decoration: none;

    @media (min-width: $tabletLarge) {
        margin-right: unset;
        margin-left: auto;
        margin-bottom: 2rem;
    }
}

.extendTitle {
    padding-left: 10rem;
    padding-right: 10rem;
}

.extendParagraph {
    @media (max-height: 880px) : {
        font-size: 1rem;
        line-height: 1.55rem;
    }
}
