.extendSection {
    background-color: var(--white);
    color: var(--black);
    height: 100%;
}

.extendTitle {
    color: var(--blue);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--black);
}

$colSpacing: 16;

.grid {
    margin-top: 3.75rem;
    max-width: 58rem;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $desktop) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: $desktopFullHD) {
        max-width: 73rem;
    }
}

.col {
    display: flex;
    margin: $(colSpacing)px;
    text-decoration: none;

    @media (min-width: $desktop) {
        flex: 0 0 calc(50% - calc($(colSpacing)px * 2));
    }
}

.readMoreWrapper {
    margin-top: 3.25rem;
    text-align: center;
}

.extendCardBody {
    padding: 0;
    min-height: unset;
}

.extendCardWrapper {
    height: 100%;
    flex-grow: 1;
}
