@keyframes rotation {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(-360deg);
    }
}

.logo{
    animation: 5s rotation 1 linear;
    animation-iteration-count: infinite;
}