.link {
    padding: 16px;

    display: flex;
    align-items: center;
    text-decoration: none;
}

.box {
    background-color: var(--blue);
    height: 2.5rem;
    width: 2.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--white);
    margin-right: 2.5rem;
}

.extendSectionInner {
    max-width: 70rem;
    color: var(--white);

    display: grid;
    grid-template-columns: auto;

    @media (min-width: $phoneLarge) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $tablet) {
        grid-template-columns: repeat(3, 1fr);
    }
}
