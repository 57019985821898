
.wrapper {
    border: 2px solid var(--black);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;

    &.wrapper:hover {
        &.wrapper [role="link"] {
            opacity: 0.3;
        }
    }
}

.body {
    width: 100%;
    height: 100%;
    min-height: 300px;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer {
    border-top: 2px solid var(--black);
    height: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 1.25rem;
    font-weight: 100;
}

.button {
    display: flex;
    justify-content: center;
    background-color: var(--cyan);
    width: 3.125rem;
    align-items: center;
    padding: 0.625rem;
    text-align: center;
    height: 100%;
    border-left: 2px solid var(--black);
    text-decoration: none;
    transition: 0.3s opacity ease;
}
