.extendSection {
    background-color: var(--blue);
    color: var(--white);
    max-width: 88rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.extendTitle {
    color: var(--white);
    font-size: 2.5rem;
    letter-spacing: 1.82px;
    text-shadow: 2px 2px var(--black);
}

.grid {
    margin-top: 3.75rem;
    @media (min-width: $desktop) {
        display: flex;
        flex-wrap: wrap;
    }

    /* maxWidth: 53rem; */
    margin-left: auto;
    margin-right: auto;
}

.readMoreWrapper {
    margin-top: 3.25rem;
    text-align: center;
}
